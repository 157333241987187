<template>
  <Header />
  <FruitSearch />
</template>

<script>
import Header from './components/Header.vue';
import FruitSearch from './components/FruitSearch.vue';

export default {
  name: 'App',
  components: {
    Header,
    FruitSearch
  }
}
</script>

<style>

</style>
