<template>
    <h1>Live search storitev</h1>
</template>

<script>
export default {
    name: 'Header'
}
</script>

<style scoped>
    h1{
        font-weight: 600;
        font-size: 3rem;
        text-transform: capitalize;
        text-align: center;
        background-color: #E8ECEF;
        padding: 3rem 0;
    }
</style>
